import React from "react"
import TemplatePageGuide from "@components/pageGuide/template"
import { Helmet } from "react-helmet"
import data from "@components/pageGuide/guides/guide76/data"

const IndexPage = ({ location }) => (
  <div className="fnd">
    <Helmet htmlAttributes={{ lang: 'es' }}>
      <title>
        ¿Cómo vender seguros por teléfono bajo una estrategia omnicanal?
        [Antes Securitec]</title>
      <link
        rel="icon"
        type="image/png"
        href={`https://beexcc.com/Beex.png`}
      />
      <link
        rel="canonical"
        href={`https://beexcc.com/recursos/guias/leads-seguros-whatsapp/`}
      />
      <meta name="robots" content="index, follow"></meta>
      <meta charSet="utf-8" />
      <meta
        name="description"
        content="Descubre cómo una estrategia omnicanal puede mejorar la venta de seguros por teléfono y optimizar cada interacción con los clientes en distintos canales. "
      />
      <meta property="og:locale" content="es_ES" />
      <meta property="og:type" content="website" />
      <meta
        property="og:title"
        content="¿Cómo vender seguros por teléfono bajo una estrategia omnicanal?"
      />
      <meta
        property="og:url"
        content={`https://beexcc.com/recursos/guias/leads-seguros-whatsapp/`}
      />
      <meta
        property="og:image"
        content={`https://beexcc.com/metaimagenRecursos/Guias/og-imgGuia76.jpg`}
      />
      <meta
        property="og:description"
        content="Descubre cómo una estrategia omnicanal puede mejorar la venta de seguros por teléfono y optimizar cada interacción con los clientes en distintos canales. "
      />
    </Helmet>
    <TemplatePageGuide
      ebook="Seguros"
      location={location}
      typeGuide="guide76"
      clickHereHref="https://beexcc.com/blog/"
      data={data}
    />
  </div>
)

export default IndexPage
